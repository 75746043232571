<template>
    <div class="main-dask-company-page">
        <div class="element-place">
            <div class="control-place">
                <input type="text" class="filter-line" placeholder="Поиск">
                <p class="plain-text column-name">{{leftPartData.nameColumn}} {{ additionalName }}</p>
                <button
                    class="add-line"
                    :class="{
                        'cancel-add-line': flagCreateForm,
                        'hiden-button': userOptions.role != 'developer' && userOptions.role != 'admin' && leftPartData.nameColumn == 'Диллеры'
                    }"
                    @click="clickCreateLine"
                    :disabled="
                        // leftPartData.nameColumn == 'Компании'
                        userOptions.role != 'developer' && userOptions.role != 'admin' && leftPartData.nameColumn == 'Диллеры'
                    "
                >
                    <p class="sign-button">+</p>
                    <p class="text-button" v-if="!flagCreateForm">&nbsp;Добавить</p>
                    <p class="text-button" v-if="flagCreateForm">&nbsp;Отменить</p>
                </button>
            </div>
            <CompanyList
                :lineList="workList"
                :choosedLineId="leftPartData.choosedCompanyId"
                :flagShowBack="leftPartData.nameColumn == 'Компании'"
                @clickBack="backClickOfList"
                @clickLine="chooseElementList"
            />
        </div>
        <div class="middle-column">
            <Transition name="fade" mode="out-in">
                <component
                    :is="middleColumn"
                    :nameElement="middlePart.nameElement"
                    :shortNameElement="middlePart.shortNameElement"
                    :nameElementForm="middlePart.nameForm"
                    :typeForm="typeMiddleForm"
                    :typeElement="leftPartData.nameColumn"
                    :idDealer="middlePart.choosedDealerParam.id"
                    :idCompany="leftPartData.choosedCompanyId"
                    :flagChangeForm="middlePart.changeFlagUser"
                    :paramUserChangePassword="middlePart.changeUserParam"
                    :key="middlePart.key"
                    @clickChangePassword="changePassword"
                    @closeFormChangePassword="closeChangeUserForm"
                    @createElement="createElementLine"
                    @changeDealer="changeDealerLine"
                    @changeCompany="changeCompanyLine"
                    @addNewUser="addNewElementUser"
                    @clickDeleteDealer="deleteDealer"
                    @clickDeleteCompany="deleteCompany"
                />
            </Transition>
        </div>
        <div class="right-column">
            <Transition name="fade" mode="out-in">
                <component
                    :is="rightColumn"
                    :userList="userList"
                    :key="rightPart.key"
                    @deleteUser="deleteUserForm"
                    @changeUser="openFormChangeUser"
                />
            </Transition>
        </div>
    </div>
</template>

<script>
import { shallowRef } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import CreateDealerCompanyForm from '@/components/Simple_elements/CreateDealerCompanyForm.vue'
import CompanyList from '@/components/Simple_elements/CompanyList.vue'
import DealerCompanyForm from '@/components/Simple_elements/DealerCompanyForm.vue'
import UsersListForm from './UsersListForm.vue'

export default {
    name: 'Company',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            dealerList: 'getDealerListByCompanyPage',
            userList: 'getUserListByCompanyPage'
        }),
        workList() {
            if (this.leftPartData.nameColumn == 'Диллеры') {
                return this.dealerList
            } else if (this.leftPartData.nameColumn == 'Компании') {
                return this.leftPartData.companyList
            } else {
                return []
            }
        },
        additionalName() {
            if (this.leftPartData.choosedDealerName == '') {
                return ''
            } else {
                return '(' +this.leftPartData.choosedDealerName + ')'
            }
        },
        typeMiddleForm() {
            return this.leftPartData.choosedCompanyId==0?'dealer':'company'
        }
    },
    data() {
        return {
            flagShowCompany: false,
            flagCreateForm: false,
            middleColumn: shallowRef(),
            rightColumn: shallowRef(),
            leftPartData: {
                nameColumn: 'Диллеры',
                choosedDealerName: '',
                choosedCompanyId: 0,
                companyList: [],
            },
            middlePart: {
                changeUserParam: {},
                choosedDealerParam: {},
                changeFlagUser: false,
                nameForm: '',
                nameElement: '',
                shortNameElement: '',
                key: 0
            },
            rightPart: {
                userList: [],
                key: 0
            }
        }
    },
    components: {
        CreateDealerCompanyForm,
        CompanyList,
        DealerCompanyForm,
        UsersListForm
    },
    methods: {
        ...mapActions({
            getDealerList: 'getApiDealerListByCompanyPage',
            addDealerToList: 'postApiDealerListByCompanyPage',
            changeDealerParam: 'putApiDealerListByCompanyPage',
            deleteDealerList: 'deleteApiDealerListByCompanyPage',
            addCompanyToList: 'postApiCompanyListByCompanyPage',
            changeCompanyParam: 'putApiCompanyListByCompanyPage',
            deleteCompanyList: 'deleteApiCompanyListByCompanyPage',
            getUserList: 'getApiUserListByCompanyPage',
            addUserPost: 'postApiUserByCompanyPage',
            deleteUser: 'deleteApiUserByCompanyPage',
            changeUser: 'putApiUserByCompanyPage'
        }),
        deleteUserForm(param) {
            if (confirm('Удалить пользователя "' + param.name +'"')) {
                this.deleteUser(param.id)
            }
        },
        changePassword(param) {
            let options = {
                ...param,
                company_id: this.leftPartData.choosedCompanyId==0?null:this.leftPartData.choosedCompanyId,
                dealer_id: this.middlePart.choosedDealerParam.id,
                username: this.middlePart.choosedDealerParam.name
            }
            this.changeUser(options)
        },
        openFormChangeUser(param) {
            this.middlePart.changeFlagUser = true
            if (this.leftPartData.choosedCompanyId == 0) {
                this.middlePart.changeUserParam = param
                this.middlePart.changeUserParam.type = 'dealer'
                // alert('change dealer')
            } else {
                this.middlePart.changeUserParam = param
                this.middlePart.changeUserParam.type = 'company'
                // alert('change company')
            }
            console.log(this.middlePart.changeUserParam)
        },
        closeChangeUserForm() {
            this.middlePart.changeFlagUser = false
        },
        addNewElementUser(options) {
            this.addUserPost(options)
        },
        clickCreateLine() {
            if (this.flagCreateForm == true) {
                this.middleColumn = shallowRef()
                this.flagCreateForm = false
                if (this.leftPartData.nameColumn == 'Компании') {
                    this.middleColumn = shallowRef(DealerCompanyForm)
                    this.rightColumn = shallowRef(UsersListForm)
                }
            } else {
                this.chooseElementList({id: 0})
                this.middleColumn = shallowRef(CreateDealerCompanyForm)
                this.rightColumn = shallowRef()
                this.flagCreateForm = true
            }
        },
        createElementLine(nameElement, shortNameElement, typeElement) {
            let options = {
                name: shortNameElement,
                showing_name: nameElement
            }
            if (typeElement == "Диллеры") {
                this.addDealerToList(options)
                // this.chooseElementList({id: 0})
                this.flagCreateForm = false
                this.middleColumn = shallowRef()
                this.rightColumn = shallowRef()
            } else {
                options.dealer_id = this.middlePart.choosedDealerParam.id
                this.addCompanyToList(options)
                this.flagCreateForm = false
                this.middleColumn = shallowRef(DealerCompanyForm)
                this.rightColumn = shallowRef(UsersListForm)
            }
        },
        async changeDealerLine(options) {
            await this.changeDealerParam(options);
            this.dealerList.forEach(el=>{
                if (el.id == options.id) {
                    // console.log()
                    this.middlePart.nameElement = el.showing_name
                    this.middlePart.shortNameElement = el.name
                    this.middlePart.nameForm = "Форма диллера " + el.showing_name
                    this.leftPartData.choosedDealerName = el.showing_name
                }
            })
        },
        async deleteDealer(options) {
            await this.deleteDealerList(options)
            this.leftPartData.nameColumn = "Диллеры"
            this.leftPartData.choosedDealerName = ""
            this.leftPartData.choosedCompanyId = 0
            this.leftPartData.companyList = []
            this.middlePart.choosedDealerParam = {}
            this.middleColumn = shallowRef()
            this.rightColumn = shallowRef()
        },
        async deleteCompany(options) {
            await this.deleteCompanyList(options)
            this.dealerList.forEach(el => {
                // console.log(el)
                if (this.middlePart.choosedDealerParam.id == el.id) {
                    this.leftPartData.companyList = el.companies
                }
            })
        },
        async changeCompanyLine(options) {
            await this.changeCompanyParam(options);
            this.dealerList.forEach(el => {
                if (options.param.dealer_id == el.id) {
                    this.leftPartData.companyList = el.companies
                }
            })
            this.leftPartData.companyList.forEach(el=>{
                this.middlePart.nameElement = el.showing_name
                this.middlePart.shortNameElement = el.name
                this.middlePart.nameForm = "Форма компании " + el.showing_name
            })
        },
        chooseElementList(paramEl) {
            this.middlePart.changeFlagUser = false
            if (this.leftPartData.nameColumn == 'Диллеры') {
                if (paramEl.id) {
                    if(this.flagCreateForm == true) {
                        this.clickCreateLine()
                    }
                    this.leftPartData.companyList = paramEl.companies
                    this.leftPartData.nameColumn = 'Компании'
                    this.leftPartData.choosedDealerName = paramEl.name
                    this.middleColumn = shallowRef(DealerCompanyForm)
                    this.middlePart.nameForm = "Форма диллера " + paramEl.name
                    this.middlePart.nameElement = paramEl.name
                    this.middlePart.shortNameElement = paramEl.shortName
                    this.middlePart.key = 1

                    this.middlePart.choosedDealerParam = paramEl
                    // console.log()
                    this.getUserList({
                        id: paramEl.id,
                        type: 'dealer'
                    })
                    this.rightColumn = shallowRef(UsersListForm)
                    this.rightPart.key = 1
                } else {
                    this.leftPartData.companyList = []
                    this.leftPartData.nameColumn = 'Диллеры'
                    this.middlePart.nameForm = ''
                    this.middlePart.nameElement = ''
                    this.middlePart.shortNameElement = ''
                    this.leftPartData.choosedDealerName = ''
                    this.middlePart.key = 0
                    this.middlePart.choosedDealerParam = {}
                    this.middleColumn = shallowRef()
                    this.rightColumn = shallowRef()
                    this.rightPart.key = 0
                }
            } else {
                if (paramEl.id) {
                    this.leftPartData.choosedCompanyId = paramEl.id
                    if(this.flagCreateForm == true) {
                        this.clickCreateLine()
                    }
                    this.getUserList({
                        id: paramEl.id,
                        type: 'company'
                    })
                    this.middleColumn = shallowRef(DealerCompanyForm)
                    this.middlePart.nameForm = "Форма компании " + paramEl.name
                    this.middlePart.nameElement = paramEl.name
                    this.middlePart.key = 2
                    this.middlePart.shortNameElement = paramEl.shortName
                    this.rightPart.key = 2
                } else {
                    this.middlePart.key = 0
                    this.rightPart.key = 0
                    this.leftPartData.choosedCompanyId = 0
                    this.getUserList({
                        id: this.middlePart.choosedDealerParam.id,
                        type: 'dealer'
                    })
                    this.middlePart.nameForm = "Форма диллера " + this.middlePart.choosedDealerParam.name
                    this.middlePart.nameElement = this.middlePart.choosedDealerParam.name
                    this.middlePart.shortNameElement = this.middlePart.choosedDealerParam.shortName
                }
            }
        },
        backClickOfList() {
            this.leftPartData.nameColumn = 'Диллеры'
            this.leftPartData.choosedCompanyId = 0
            this.leftPartData.companyList = []
            this.leftPartData.choosedDealerName = ''
            if (this.middleColumn.name == 'CreateDealerCompanyForm') {
                this.clickCreateLine()
                console.log(33)
            } else {
                this.middleColumn = shallowRef()
            }
            this.rightColumn = shallowRef()
        }
    },
    created() {
        this.getDealerList();
        // this.leftPartData.showedList = this.dealerList;
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.main-dask-company-page{
    height: 93vh;
    padding: 32px 1vw;
    overflow-y: auto;
    display: flex;

    .element-place {
        width: 40vw;
        padding: 0 10px;
        
        .control-place {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 25px;
            
            .column-name {
                color: var(--gray-dark-color);
            }

            .filter-line {
                width: 72px;
                font-weight: 400;
                font-size: 14px;
                color: var(--gray-dark-color);
                // border: 0 solid var(--gray-dark-color);
                border-bottom: 1px solid var(--gray-dark-color);
            }
            .add-line {
                border: 1px solid var(--gray-dark-color);
                background: none;
                border-radius: 3px;
                padding: 5px 15px;
                width: 121px;
                height: 27px;
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 14px;
                color: var(--black-color);
                transition: all .3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    transition: all .3s ease;
                }

                &:hover {
                    background: #94949417;
                }

                &:active {
                    background: #9494943a;
                }
                &.hiden-button {
                    opacity: 0;
                }
            }

            .cancel-add-line {
                background: var(--red-color);
                .sign-button {
                    rotate: 45deg;
                }
                &:hover {
                    background: #f97676a9;
                }

                &:active {
                    background: #f976764d;
                }
            }
            
        }

        .el-list-place {
            display: flex;
            flex-direction: column;
        }

    }
    .middle-column {
        width: 29vw;
        padding: 0 10px;
    }
    .right-column {
        width: 29vw;
        padding: 0 10px;
    }
}

</style>