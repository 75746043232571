<template>
    <div class="create-dealer-form">
        <h1 class="name-form middle-high">Добавить {{ showedNameElement }}:</h1>
        <input type="text" v-model="nameElement" class="plain-text" :placeholder="'Название ' + inputPlaceholderName">
        <input type="text" v-model="shortNameElement" class="plain-text" placeholder="Короткое имя на Английском">
        <button class="plain-text" @click="clickAddElement">Добавить</button>
    </div>
</template>

<script>

export default {
    name: 'CreateDealerCompanyForm',
    props: ['typeElement'],
    emits: ['createElement'],
    computed: {
        showedNameElement() {
            if (this.typeElement == 'Диллеры') {
                return 'диллера'
            } else {
                return 'компанию'
            }
        },
        inputPlaceholderName() {
            if (this.typeElement == 'Диллеры') {
                return 'диллера'
            } else {
                return 'компании'
            }
        }
    },
    data() {
        return {
            nameElement: '',
            shortNameElement: ''
        }
    },
    components: {
    },
    methods: {
        clickAddElement() {
            this.$emit('createElement', this.nameElement, this.shortNameElement, this.typeElement);
            this.nameElement = '';
            this.shortNameElement = '';
        },
    },
    created() {
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.create-dealer-form {
    // position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 25px;

    input {
        border: 1px solid var(--gray-dark-color);
        border-radius: 4px;
        padding: 3px 6px;
        margin-top: 20px;
    }

    button {
        border-radius: 8px;
        background: var(--background-blue);
        color: #fff;
        padding: 6px 50px;
        margin-top: 20px;
        margin-left: auto;
        transition: all .1s ease;

        &:hover {
            background: #3f51b5d0;
        }
        &:active {
            background: #3f51b55e;
        }
    }
}

</style>